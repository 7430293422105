<script>
export default {
  name: 'TableauCommun',
  props: {
    headers: { required: false, type: Array, default: () => [{ text: 'default', value: 'nom' }, { text: 'default', value: 'prenom' }] },
    items: { required: false, type: Array, default: () => [] },
    loading: { reuqired: false, type: Boolean, default: false },
    maxShow: { required: false, type: Number, default: 10 },
    nbTotal: { required: false, type: Number, default: 25 },
    extraContent: { required: false, type: Boolean, default: false },
    singleExpand: { required: false, type: Boolean, default: true },
    routeName: { required: false, type: String, default: 'home___fr' },
    noDataText: { required: false, type: String },
    hideFooter: { required: false, type: Boolean, default: false }
  },
  data () {
    return {
      limit: 10,
      rounded: true,
      options: {
        page: 1,
        limit: 25,
        itemsPerPage: this.maxShow
      }
    }
  },
  methods: {
    getRoute (item) {
      if (this.routeName === 'commandeDetail') {
        return { name: 'commandesdetail___' + this.$i18n.locale, params: { lang: this.$i18n.locale, id: item.id } }
      } else if (this.routeName === 'enseignantDetail') {
        return { name: 'enseignantdetail___' + this.$i18n.locale, params: { lang: this.$i18n.locale, id: item.id } }
      }
    }
  }
}
</script>

<template lang="pug">
.tableau-commun
  v-data-table(
    :headers='headers',
    :items='items',
    :loading='loading',
    :items-per-page.sync='limit',
    :server-items-length='nbTotal',
    :options.sync='options',
    :single-expand='singleExpand',
    :show-expand='extraContent',
    :no-data-text='noDataText || $t("alerte.aucun-resultat")',
    :no-results-text='$t("alerte.aucun-result-recherche")',
    :mobile-breakpoint='0',
    :class='{"light" : $store.state.Preferences.darkMode, "white" : !$store.state.Preferences.darkMode}'
    :style='{borderRadius: rounded ? "10px" : "0px"}',
    :hide-default-footer='nbTotal < maxShow || hideFooter',
    light
  )
    template(v-slot:item.actions='{ item }')
      v-btn.white--text.mb2(:small='$store.state.App.mobileTemplate', :to='getRoute(item)', icon, color='primary')
        font-awesome-icon.f4(:icon="['fas', 'arrow-right']")
</template>

<style>
</style>
